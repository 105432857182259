<template>
	<div>
		<hero title="Industry Training Made Easy" class="mb-6" image="enterprise.jpg" small>
			<h4 class="text-grey-2 pr-3">Do you want the flexibility to upskill your people with the knowledge they need, when they need it? <span class="moving-underline" v-in-viewport>WiseTech Academy</span> can help.</h4>
		</hero>
		<b-container>
			<b-row>
				<b-col md="6" class="mb-4 mb-md-0">
					<heading>Flexible Learning</heading>
					<p>We offer flexible, convenient and affordable online learning to suit individual members of your team or your organization as a whole.</p><p>

Whether it's Corporate Compliance or International Trade, our online courses are designed to help you take care of your organization's bulk training needs as efficiently and cost effectively as possible.</p>
				</b-col>
				<b-col md="6" class="mb-4 mb-md-0">
					<heading>We Make It Easy for You</heading>
					<p>
						Our online courses are targeted, engaging and easy to access.
					</p>
					<p>
						That way, you and your team can complete them as and when you need to instead of being locked into a lengthy and costly course.
					</p>
					<p>
						Simply choose the programs that best suit your team and organization, and equip your people with the skills and knowledge they need to become more efficient and productive in their roles.
					</p>
				</b-col>
			</b-row>
		</b-container>
		<contact-form full title="Like to keep informed?"></contact-form>
	</div>
</template>

<script>
export default {
	name: 'Enterprise',
}
</script>
